<template>
	<div class="rootView">


		<div class="headView" style="background-color:rgba(0, 0, 0, 0.3) " v-show="color == false">
			<div :style="style" :class="['headLeft', { color: color }]">
				<img src="../../assets/homeimg/profile.png" style="height: 60px;padding-left: 40px;" alt="" />
				<div >
					辽宁烽火台科技有限公司
				</div>
			</div>
			<div :style="style" :class="['headRight', { color: color }]">
				<div class="headTit" @click="homeClick()" >
					首页
				</div>
				<div class="headTit" @click="newsClick()"  >
					新闻动态
				</div>
				<div class="headTit" @click="productClick()">
					产品方案
				</div>
				<!-- <div class="headTit" style="color: #ff8c2e;">
					解决方案
				</div> -->
				<div class="headTit" @click="aboutClick()">
					关于我们
				</div>


			</div>

		</div>
		<div class="headView" style="background-color: white;" v-show="color == true">
			<div :style="style" :class="['headLeft', { color: color }]">
				<img src="../../assets/homeimg/profile.png" style="height: 60px;padding-left: 40px;" alt="" />
				<div >
					辽宁烽火台科技有限公司
				</div>
			</div>
			<div :style="style" :class="['headRight', { color: color }]">
				<div class="headTit" @click="homeClick()">
					首页
				</div>
				<div class="headTit" @click="newsClick()"  >
					新闻动态
				</div>
				<div class="headTit" @click="productClick()">
					产品方案
				</div>
				<!-- <div class="headTit" style="color: #ff8c2e;">
					解决方案
				</div> -->
				<div class="headTit" @click="aboutClick()">
					关于我们
				</div>


			</div>

		</div>
		<div class="imgView">
			<div class="contentView">
				<div class="contentTit" data-aos="fade-down-right" >
					Solution.
				</div>
				<div class="contentTit2" data-aos="fade-down-left">
					解决方案
				</div>
			</div>
		</div>
		<div class="newsView" v-for="(item, index) in solutionList" :key="index" @click="detailsClick1(item)" >
			<div class="newsListView" v-if="index%2 == 0" >
				<img data-aos="fade-up-left" :src="item.coverImg" style="width: 50%;height: 530px;" alt="" srcset="" />
				<div class="newsListTit" data-aos="fade-up-right">
					<div class="newsListTit1">
						{{item.title}}
					</div>
					<div class="newsListTitLine">
						
					</div>
					<div class="newsListTit2">
                         创建人:{{item.createUser}}
					</div>
					
				</div>
			</div>
			
			<div class="newsListView" v-else data-aos="fade-up-right">
				
				<div class="newsListTit">
					<div class="newsListTit1">
						{{item.title}}
					</div>
					<div class="newsListTitLine">
						
					</div>
					<div class="newsListTit2">
                         创建人:{{item.createUser}}
					</div>
					
				</div>
				<img data-aos="fade-up-left" :src="item.coverImg" style="width: 50%;height: 530px;" alt="" srcset="" />
			</div>
			
			
			
			
			

		</div>
		

		<div class="footView"
			style="background-color: black;width: 100%;height: 300px;display: flex;justify-content: center;align-items: center;flex-direction: column;">
			<div style="width: 50%;color: #ffffff;font-size: 14px;display: flex;justify-content: center;">
				<div style="width: 50%;">
					<div>
						辽宁烽火台科技有限公司官网
					</div>
					<div style="margin-top: 20px;">
						电话：400-1023458
					</div>
					
					<div style="margin-top: 20px;">
						地址：辽宁省鞍山市高新技术开发区越岭路256号
					</div>
				</div>
				<div >
					<img src="../../assets/homeimg/gongzhong.jpg" style="width: 100px;height: 100px;" alt="" />
					<div>
						关注微信公众号
					</div>
				</div>
			</div>
			<div style="width: 50%;color: #ffffff;font-size: 14px;display: flex;justify-content: center;flex-direction: column;align-content: center;align-items: center;">
				<div style="margin-top: 20px;">
					<a style="color: white;" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">辽ICP备14005170号-1</a>
				</div>
				<div style="margin-top: 20px;">
					<a style="color: white;" href="https://dxzhgl.miit.gov.cn/" target="_blank">增值电信业务经营许可证：辽B2-2014143</a>
				</div>
			</div>
		</div>



	</div>
</template>
<script>
	import BannerFade from './BannerSlide.vue'
	import {solutionPage} from "../../api/home/home.js"
	

	import VueCountUp from 'vue-countupjs' //引入
	import {
		v4
	} from 'uuid';
	import {
		getSign
	} from '@/utils'

	export default {
		name: "home",
		components: {
			BannerFade
		},
		data() {
			return {
				fullWidth: document.documentElement.clientWidth,
				fullHeight: document.documentElement.clientHeight,

				
				style: {
					backgroundColor: ""
				},
				solutionList:[],
				queryParams: {
				  page: 1,
				  size: 6,
				},
				color: false,
				scrollTop: 0,
				list:['1','1','1','1','1','1','1']
			};
		},
		watch: {
			fullHeight(val) {

				if (!this.timer) {
					this.fullHeight = val
					this.timer = true
					let that = this
					setTimeout(function() {
						that.timer = false
					}, 400)
				}
			},
			fullWidth(val) {

				if (!this.timer) {
					this.fullWidth = val
					this.timer = true

					let that = this
					setTimeout(function() {
						that.timer = false
					}, 400)
				}
			}
		},
		computed: {

		},
		created() {
			this.createData();
			// this.get_bodyWidth();
			// this.get_bodyHeight();


		},

		methods: {
			createData() {
				
				var that = this
				solutionPage(this.queryParams.page,this.queryParams.size).then(response => {
					this.solutionList = response.data.list;
				
				}).catch(() => {});
			
			},
			getNowTime() {
				var date = new Date();
				var time = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + this.addZero(date.getDate()) + ' ' +
					this.addZero(date.getHours()) + ':' + this.addZero(date.getMinutes()) + ':' + this.addZero(date
						.getSeconds());
				this.newTime = time;
			},
			//根据自己的需求，看要不要在时间不大于10的时候在前面补0，如果需要直接this.addZero(date.getMinutes()),其它与之相同，如果不需要删掉addZero（）方法即可。
			//小于10的拼接上0字符串
			addZero(s) {
				return s < 10 ? ('0' + s) : s;
			},

			get_bodyHeight() {
				const that = this
				window.onresize = () => {
					return (() => {
						window.fullHeight = document.documentElement.clientHeight
						that.fullHeight = window.fullHeight
					})()
				}
			},
			get_bodyWidth() {
				const that = this
				window.onresize = () => {
					return (() => {
						window.fullWidth = document.documentElement.clientWidth
						that.fullWidth = window.fullWidth
					})()
				}
			},
			handleScroll() {
				let scrollTop =
					window.pageYOffset ||
					document.documentElement.scrollTop ||
					document.body.scrollTop;
				if (scrollTop >= 580) {
					this.style.color = `rgba(0, 0, 0,${
				  scrollTop / (scrollTop + 20)
				}) `;
					this.color = true;
				} else if (scrollTop == 0) {
					this.style.color = "white";
					this.color = false;
				}
			},
			//首页
			homeClick(){
				this.$router.push({
				  name: 'home',
				  
				})
			},
			//关于我们
			aboutClick(){
				this.$router.push({
				  name: 'aboutUs',
				  
				})
			},
			newsClick(){
				this.$router.push({
				  name: 'news',
				  
				})
			},
			productClick(){
				this.$router.push({
				  name: 'product',
				  
				})
			},
			detailsClick1(row){
				const solutionId = row.solutionId
				const query = { solutionId: solutionId}
				this.$router.push({
				  name: 'SolutionDetail',
				  query: query
				  
				})
			},
			
			
			

		},
		mounted() {
			window.addEventListener("scroll", this.handleScroll);
		},
		beforeDestroy() {
			window.removeEventListener("scroll", this.handleScroll);
		},

	};
</script>

<style lang="scss" scoped>
	.rootView {
		width: 100%;
	}

	.headView {
		position: fixed;
		width: 100%;
		z-index: 9999;
		display: flex;
		
		height: 80px;
	}

	.headLeft{
		display: flex;
		justify-content:center;
		align-items: center;
		font-size: 25px;
		font-family: led;
		color: white;
	}

	.headRight {
		float: right;
		position: absolute;
		right: 50px;
		width: 500px;
		color: white;
		// font-weight: bold;
		font-family: led;
		
		font-size: 20px;
		text-align: right;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.headTit{
		padding-top: 30px;
	}
	@font-face {
		font-family: led;
		src: url('../../../public/AlimamaShuHeiTi-Bold.ttf');
	}
	.imgView{
		width: 100%;
		height: 600px;
		background-image: url("../../assets/homeimg/-48620.jpg");
		background-position: 50% 50%;
		background-size: cover;
		// background: url("../../assets/homeimg/-48620.jpg") no-repeat 0/100% 100%;
		justify-content: center;
		display: flex;
		align-items: center;
	}
	.contentView{
		width: 50%;
		
		display: flex;
		flex-direction: column;	
	}
	.contentTit{
		font-family: "Arial Black";
		font-weight: bold;
		font-size: 72px;
		color: rgba(255, 255, 255, 0.2);
	}
	.contentTit2{
		font-family: "Source Han Sans";
		font-weight: bold;
		font-size: 36px;
		margin-top: 10px;
		color: rgb(255, 255, 255);
	}
	.newsView{
		width: 100%;
		background-color: white;
		display: flex;
		margin-top: 50px;
		flex-direction: column;
		
		margin-bottom: 20px;
	}
	.newsListView{
		width: 100%;
		
		display: flex;
		
	}
	.newsListTit{
		width: 50%;
		display: flex;
		flex-direction: column;
		padding-left: 10%;
		
	}
	.newsListTit1{
		padding-top: 10%;
		font-family: Source Han Sans,Geneva,sans-serif;
		color: #333333;
		font-weight: bold;
		font-size: 32px;
	}
	.newsListTitLine{
		width: 200px;
		height: 2px;
		margin-top: 20px;
		background-color: #856dcc;
	}
	.newsListTit2{
		margin-top: 20px;
		font-family: Source Han Sans,Geneva,sans-serif;
		font-size: 14px;
		line-height: 2;
		text-align: justify;
		color: #666666;
		width: 50%;
	}
	.footView{
		background-image: url("../../assets/homeimg/footImg.png");
		background-position: 50% 50%;
		background-size: cover;
		
	}
	
	
	
	
	

</style>