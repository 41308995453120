<template>
	<div class="rootView">


		<div class="headView" style="background-color:rgba(0, 0, 0, 0.3) " v-show="color == false">
			<div :style="style" :class="['headLeft', { color: color }]">
				<img src="../../assets/homeimg/profile.png" style="height: 60px;padding-left: 40px;" alt="" />
				<div >
					辽宁烽火台科技有限公司
				</div>
			</div>
			<div :style="style" :class="['headRight', { color: color }]">
				<div class="headTit" style="color: #ff8c2e;">
					首页
				</div>
				<div class="headTit" @click="newsClick()">
					新闻动态
				</div>
				<div class="headTit" @click="productClick()">
					产品方案
				</div>
				<!-- <div class="headTit" @click="SolutionClick()" >
					解决方案
				</div> -->
				<div class="headTit" @click="aboutClick()">
					关于我们
				</div>



			</div>

		</div>
		<div class="headView" style="background-color: white;" v-show="color == true">
			<div :style="style" :class="['headLeft', { color: color }]">
				<img src="../../assets/homeimg/profile.png" style="height: 60px;padding-left: 40px;" alt="" />
				<div >
					辽宁烽火台科技有限公司
				</div>
			</div>
			<div :style="style" :class="['headRight', { color: color }]">
				<div class="headTit" style="color: #ff8c2e;">
					首页
				</div>
				<div class="headTit" @click="newsClick()">
					新闻动态
				</div>
				<div class="headTit" @click="productClick()" >
					产品方案
				</div>
				<!-- <div class="headTit" @click="SolutionClick()">
					解决方案
				</div> -->
				<div class="headTit" @click="aboutClick()">
					关于我们
				</div>


			</div>

		</div>
		<BannerFade  :imageData="imageData" style="width: 100%;"  :height="fullHeight" />
		<div class="contentView">
			<div
				style="background-color: white;width: 100%;display: flex;justify-content: center;align-items: center;flex-direction: column;height: 600px;">
				<div class="contentTit" >
					About.
				</div>
				<div class="contentTit2">
					关于我们
				</div>
				<div class="contentTit3">

				</div>
				<div class="contentTit4">
					辽宁烽火台科技有限公司成立于2010年，是一家致力于北斗信息技术应用的高科技服务型企业，是国家高新技术企业、省专精特新企业、省数字化转型促进中心。

				</div>
				<div class="contentNumView">
					<div class="contentNum" data-aos="fade-right">
						<img src="../../assets/homeimg/drop.png" style="width: 56px;height: 56px;" alt="" />
						<div class="contentNumT">
							50,000
						</div>
						<div class="contentNumT2">
							连接数据节点
						</div>
					</div>
					<div class="contentNum" data-aos="fade-down">
						<img src="../../assets/homeimg/people.png" style="width: 56px;height: 56px;" alt="" />
						<div class="contentNumT">
							20,000
						</div>
						<div class="contentNumT2">
							覆盖人口
						</div>
					</div>
					<div class="contentNum" data-aos="fade-left">
						<img src="../../assets/homeimg/area.png" style="width: 56px;height: 56px;" alt="" />
						<div class="contentNumT">
							80,000
						</div>
						<div class="contentNumT2">
							覆盖面积（m²）
						</div>
					</div>
				</div>

			</div>
			<div class="serviceView">
				<div class=""
					style="width: 100%;display: flex;justify-content: center;align-items: center;flex-direction: column;margin-bottom: 20px;">
					<div class="contentTit" style="color: rgba(255, 255, 255, 0.2);">
						Service.
					</div>
					<div class="contentTit2" style="color: white;">
						我们的服务
					</div>
					<div class="contentTit3">

					</div>
					<div class="contentTit4" style="color: white;">
						以人工智能+物联网技术打造 AI CITY, 为政府、企业提供城市管理、建筑能源管理、环境与基础设施运营管理等一站式解决方案。

					</div>
				</div>

				<div class="programmeView">
					<div class="programmeViewC" data-aos="zoom-in-right" style="background-color: white;">
						<div class="programmeCT">
							Gaia AIoT Community
						</div>
						<div class="programmeCT2">
							智能社区平台
						</div>
						<div class="programmeCT3">
							主要应用于智慧社区的实战指挥平台，终提高管理、服务决策的智能化水平
						</div>

						<!-- <div class="buttonView" >
							
						</div> -->



					</div>
					<div class="programmeViewC" data-aos="zoom-in-left" style="background-color: rgb(133, 109, 204);">
						<div class="programmeCT" style="color: white;">
							Gaia AIoT Community
						</div>
						<div class="programmeCT2" style="color: white;">
							智能社区平台
						</div>
						<div class="programmeCT3" style="color: white;">
							主要应用于智慧社区的实战指挥平台，终提高管理、服务决策的智能化水平
						</div>

					</div>


				</div>

			</div>




		</div>
		<div style="background-color: white;width: 100%;display: flex;justify-content: center;align-items: center;">
			<div class="programmeView" data-aos="zoom-in-down">
				<div class="programmeViewC" style="background-color: #636363;">

				</div>
				<div class="programmeViewC2" style="width: 50%;">

				</div>
				<div class="programmeViewC3" style="background-color: white;">

				</div>


			</div>


		</div>

		<div data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"
			style="background-color: white;width: 100%;display: flex;justify-content: center;align-items: center;flex-direction: column;margin-bottom: 20px;">
			<div class="contentTit">
				Advantage.
			</div>
			<div class="contentTit2">
				我们的优势
			</div>
			<div class="contentTit3">

			</div>
			<div class="contentTit4">
				以人工智能+物联网技术打造 AI CITY, 为政府、企业提供城市管理、建筑能源管理、环境与基础设施运营管理等一站式解决方案。

			</div>

		</div>
		<div class="advantageView">
			<div data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000"
				style="width: 50%;height: 190px;display: flex;justify-content: space-around;align-items: center;">
				<div class="advantageViewList">
					<img src="../../assets/homeimg/-48630.png" style="width: 50px;height: 50px;" alt="" />
					<div class="advantageViewTi">
						<div class="advantageViewTT">
							一站式数据集成
						</div>
						<div class="advantageViewTTT">
							以舒适高效、节能低耗、安全智能的全案服务，让管理有序化、集约化、高效化。
						</div>

					</div>
				</div>
				<div class="advantageViewList">
					<img src="../../assets/homeimg/-48681.png" style="width: 50px;height: 50px;" alt="" />
					<div class="advantageViewTi">
						<div class="advantageViewTT">
							分布式云端架构
						</div>
						<div class="advantageViewTTT">
							以舒适高效、节能低耗、安全智能的全案服务，让管理有序化、集约化、高效化。
						</div>

					</div>
				</div>
				<div class="advantageViewList">
					<img src="../../assets/homeimg/-48682.png" style="width: 50px;height: 50px;" alt="" />
					<div class="advantageViewTi">
						<div class="advantageViewTT">
							实时监控与反向控制
						</div>
						<div class="advantageViewTTT">
							以舒适高效、节能低耗、安全智能的全案服务，让管理有序化、集约化、高效化。
						</div>

					</div>
				</div>
			</div>
			<div data-aos="fade-up" data-aos-anchor-placement="bottom-bottom"
				style="width: 50%;height: 190px;display: flex;justify-content: space-around;align-items: center;">
				<div class="advantageViewList">
					<img src="../../assets/homeimg/-48683.png" style="width: 50px;height: 50px;" alt="" />
					<div class="advantageViewTi">
						<div class="advantageViewTT">
							专业建筑大数据分析
						</div>
						<div class="advantageViewTTT">
							以舒适高效、节能低耗、安全智能的全案服务，让管理有序化、集约化、高效化。
						</div>

					</div>
				</div>
				<div class="advantageViewList">
					<img src="../../assets/homeimg/-48691.png" style="width: 50px;height: 50px;" alt="" />
					<div class="advantageViewTi">
						<div class="advantageViewTT">
							人口信息采集存储
						</div>
						<div class="advantageViewTTT">
							以舒适高效、节能低耗、安全智能的全案服务，让管理有序化、集约化、高效化。
						</div>

					</div>
				</div>
				<div class="advantageViewList">
					<img src="../../assets/homeimg/-48690.png" style="width: 50px;height: 50px;" alt="" />
					<div class="advantageViewTi">
						<div class="advantageViewTT">
							预警安防与任务布控
						</div>
						<div class="advantageViewTTT">
							以舒适高效、节能低耗、安全智能的全案服务，让管理有序化、集约化、高效化。
						</div>

					</div>
				</div>
			</div>


		</div>
		<div style="background-color: white;width: 100%;height: 80px;">

		</div>
		<div class="newsView" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
			<div style="width: 50%;display: flex;flex-direction: column;">
				<div class="newsViewTit">
					<div
						style="font-family: Source Han Sans,Geneva,sans-serif;font-weight: bold;font-size: 32px;color: #333333;">
						新闻资讯
					</div>

					<div
						style="color: #cccccc;font-weight: bold;font-size: 35px;font-family: Arial Black;margin-left: 10px;">
						NEWS.
					</div>
				</div>
				<div style="height: 1px;width: 50%;background-color: #999999;margin-top: 10px;margin-bottom: 10px;">

				</div>
				<div style="display: flex;width: 100%;">
					<div class="newsViewCent">
						<ul>
							<li v-for="(item, index) in topNewsList" :key="index" @click="detailsClick(item)">
								<div class="newsViewCentT">
									<div class="">
										{{item.title}}
									</div>
									<span>{{item.createTime}}</span>
								</div>
								<div style="height: 1px;width: 100%;background-color: #d5d5d5;"></div>
							</li>
							
							
							
							
							
						</ul>
					</div>

					<div class="newImgView" style="">

					</div>
				</div>

			</div>
		</div>
		<div class="footView"
			style="background-color: black;width: 100%;height: 300px;display: flex;justify-content: center;align-items: center;flex-direction: column;">
			<div style="width: 50%;color: #ffffff;font-size: 14px;display: flex;justify-content: center;">
				<div style="width: 50%;">
					<div>
						辽宁烽火台科技有限公司官网
					</div>
					<div style="margin-top: 20px;">
						电话：400-1023458
					</div>
					
					<div style="margin-top: 20px;">
						地址：辽宁省鞍山市高新技术开发区越岭路256号
					</div>
				</div>
				<div >
					<img src="../../assets/homeimg/gongzhong.jpg" style="width: 100px;height: 100px;" alt="" />
					<div>
						关注微信公众号
					</div>
				</div>
			</div>
			<div style="width: 50%;color: #ffffff;font-size: 14px;display: flex;justify-content: center;flex-direction: column;align-content: center;align-items: center;">
				<div style="margin-top: 20px;">
					<a style="color: white;" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">辽ICP备14005170号-1</a>
				</div>
				<div style="margin-top: 20px;">
					<a style="color: white;" href="https://dxzhgl.miit.gov.cn/" target="_blank">增值电信业务经营许可证：辽B2-2014143</a>
				</div>
			</div>
		</div>



	</div>
</template>
<script>
	import BannerFade from './BannerSlide.vue'
	import {topNews} from "../../api/home/home.js"
	
	import VueCountUp from 'vue-countupjs' //引入
	import {
		v4
	} from 'uuid';
	import {
		getSign
	} from '@/utils'

	export default {
		name: "home",
		components: {
			BannerFade
		},
		data() {
			return {
				fullWidth: document.documentElement.clientWidth,
				fullHeight: document.documentElement.clientHeight,
				topNewsList:[],

				imageData: [{
						title: 'image-3',
						imgUrl: require('@/assets/homeimg/image-3.jpg')
					},
					{
						title: 'image-2',
						imgUrl: require('@/assets/homeimg/image-2.png')
					}


				],
				style: {
					backgroundColor: ""
				},
				color: false,
				scrollTop: 0,
			};
		},
		watch: {
			fullHeight(val) {

				if (!this.timer) {
					this.fullHeight = val
					this.timer = true
					let that = this
					setTimeout(function() {
						that.timer = false
					}, 400)
				}
			},
			fullWidth(val) {

				if (!this.timer) {
					this.fullWidth = val
					this.timer = true

					let that = this
					setTimeout(function() {
						that.timer = false
					}, 400)
				}
			}
		},
		computed: {

		},
		created() {
			this.createData();
			// this.get_bodyWidth();
			// this.get_bodyHeight();


		},

		methods: {
			createData() {
				var that = this
				topNews().then(response => {
					this.topNewsList = response.data;
				
				}).catch(() => {});

			},
			getNowTime() {
				var date = new Date();
				var time = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + this.addZero(date.getDate()) + ' ' +
					this.addZero(date.getHours()) + ':' + this.addZero(date.getMinutes()) + ':' + this.addZero(date
						.getSeconds());
				this.newTime = time;
			},
			//根据自己的需求，看要不要在时间不大于10的时候在前面补0，如果需要直接this.addZero(date.getMinutes()),其它与之相同，如果不需要删掉addZero（）方法即可。
			//小于10的拼接上0字符串
			addZero(s) {
				return s < 10 ? ('0' + s) : s;
			},

			get_bodyHeight() {
				const that = this
				window.onresize = () => {
					return (() => {
						window.fullHeight = document.documentElement.clientHeight
						that.fullHeight = window.fullHeight
					})()
				}
			},
			get_bodyWidth() {
				const that = this
				window.onresize = () => {
					return (() => {
						window.fullWidth = document.documentElement.clientWidth
						that.fullWidth = window.fullWidth
					})()
				}
			},
			handleScroll() {
				let scrollTop =
					window.pageYOffset ||
					document.documentElement.scrollTop ||
					document.body.scrollTop;
				if (scrollTop >= this.fullHeight) {
					this.style.color = `rgba(0, 0, 0,${
				  scrollTop / (scrollTop + 20)
				}) `;
					this.color = true;
				} else if (scrollTop == 0) {
					this.style.color = "white";
					this.color = false;
				}
			},
			//关于我们
			aboutClick(){
				this.$router.push({
				  name: 'aboutUs',
				  
				})
			},
			newsClick(){
				this.$router.push({
				  name: 'news',
				  
				})
			},
			//产品案例
			productClick(){
				this.$router.push({
				  name: 'product',
				  
				})
			},
			//解决方案
			SolutionClick(){
				this.$router.push({
				  name: 'Solution',
				  
				})
			},
			//详情
			detailsClick(row){
				const newsId = row.newsId
				const query = { newsId: newsId}
				this.$router.push({
				  name: 'newsDet',
				  query: query
				  
				})
			},
			

		},
		mounted() {
			window.addEventListener("scroll", this.handleScroll);
		},
		beforeDestroy() {
			window.removeEventListener("scroll", this.handleScroll);
		},

	};
</script>

<style lang="scss" scoped>
	.rootView {
		width: 100%;
	}

	.headView {
		position: fixed;
		width: 100%;
		z-index: 9999;
		display: flex;
		
		height: 80px;
	}

	.headLeft{
		display: flex;
		justify-content:center;
		align-items: center;
		font-size: 25px;
		font-family: led;
		color: white;
	}

	.headRight {
		float: right;
		position: absolute;
		right: 50px;
		width: 500px;
		color: white;
		// font-weight: bold;
		font-family: led;
		
		font-size: 20px;
		text-align: right;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.headTit{
		padding-top: 30px;
	}
	@font-face {
		font-family: led;
		src: url('../../../public/AlimamaShuHeiTi-Bold.ttf');
	}

	.contentView {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-repeat: no-repeat;
		background-size: cover;
		/* 或者其他你需要的大小设置 */
		background-position: center center;
		/* 背景图片位置 */
		background-attachment: fixed;
		/* 关键点：背景固定，不随滚动条滚动 */
		height: 1135px;
		/* 根据需要设置div的高度 */
		width: 100%;
		/* 根据需要设置div的宽度 */
		background-image: url("../../assets/homeimg/backimg.jpg");

	}

	.contentTit {
		width: 50%;
		margin-top: 40px;
		font-size: 72px;
		color: #eeeeee;
		font-weight: bold;
		font-family: Arial Black;
		text-align: center;
	}

	.contentTit2 {
		width: 50%;
		font-family: Source Han Sans, Geneva, sans-serif;
		font-size: 36px;
		text-align: center;
		font-weight: bold;
	}

	.contentTit3 {
		width: 50%;
		width: 1px;
		height: 50px;
		background-color: #727272;
		margin-top: 20px;
	}

	.contentTit4 {
		width: 50%;
		text-align: center;
		margin-top: 20px;
	}

	.contentNumView {
		display: flex;
		width: 45%;
		justify-content: space-between;
	}

	.contentNum {
		display: flex;
		flex-direction: column;
		margin-bottom: 40px;
		margin-top: 40px;
	}

	.contentNumT {
		color: #856dcc;
		font-family: Arial, Helvetica, sans-serif;
		margin-top: 20px;
		font-size: 62px;
	}

	.contentNumT2 {
		font-family: Source Han Sans, Geneva, sans-serif;
		color: #666666;
		margin-top: 20px;
		font-size: 16px;

	}

	.serviceView {
		width: 100%;
		height: 640px;

		background-color: rgba(0, 0, 0, 0);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.programmeView {
		width: 1000px;
		height: 250px;
		display: flex;
	}

	.programmeViewC {
		width: 25%;
		height: 250px;
		background-color: white;

	}

	.buttonView {

		width: 30px;
		height: 30px;
		background-color: #856dcc;
	}

	.programmeCT {
		color: #777777;
		font-size: 14px;
		letter-spacing: 1px;
		margin-top: 40px;
		margin-left: 30px;
	}

	.programmeCT2 {
		color: #856dcc;
		font-size: 24px;
		margin-left: 30px;
		margin-top: 10px;
		font-weight: bold;
		font-family: Source Han Sans, Geneva, sans-serif;
	}

	.programmeCT3 {
		margin-left: 30px;
		margin-top: 20px;
		margin-right: 20px;
		color: #666666;
		font-size: 12px;


	}

	.programmeViewC2 {
		width: 50%;
		height: 250px;
		background: url("../../assets/homeimg/program.jpg") no-repeat 0/100% 100%;
	}

	.programmeViewC3 {
		width: 25%;
		height: 250px;
		background-color: white;
		border: #eeeeee 1px solid;
	}

	@keyframes shake {

		0%,
		100% {
			transform: translateX(0);
		}

		10%,
		30%,
		50%,
		70%,
		90% {
			transform: translateX(-10px);
		}

		20%,
		40%,
		60%,
		80% {
			transform: translateX(10px);
		}
	}

	.advantageView {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: #eeeeee;
	}

	.advantageViewList {
		width: 30%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.advantageViewTi {
		display: flex;
		flex-direction: column;
		margin-left: 20px;
	}

	.advantageViewTT {
		color: #856dcc;
		font-size: 20px;
		font-weight: bold;

	}

	.advantageViewTTT {
		margin-top: 20px;
		color: #888888;
	}

	.newsView {
		width: 100%;
		height: 500px;
		display: flex;
		justify-content: center;

		background-color: #eeeeee;
	}

	.newsViewTit {
		display: flex;
		padding-top: 40px;
	}

	.newsViewCent {
		margin-top: 10px;
		width: 60%;
		color: #666666;
	}

	.newsViewCentT {
		width: 100%;
		line-height: 50px;
		display: flex;
		font-size: 12px;
		justify-content: space-between;
	}
	.newImgView{
		width: 300px;
		height: 300px;
		object-fit: cover;
		margin-left: 50px;
		background: url("../../assets/homeimg/5102318.jpg") no-repeat 0/100% 100%;
	}
	.footView{
		background-image: url("../../assets/homeimg/footImg.png");
		background-position: 50% 50%;
		background-size: cover;
		
	}
</style>