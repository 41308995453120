<template>
	<div class="rootView">


		
		
		<div class="headView" style="background-color:rgba(0, 0, 0, 0.3) " v-show="color == false">
			<div :style="style" :class="['headLeft', { color: color }]">
				<img src="../../assets/homeimg/profile.png" style="height: 60px;padding-left: 40px;" alt="" />
				<div >
					辽宁烽火台科技有限公司
				</div>
			</div>
			<div :style="style" :class="['headRight', { color: color }]">
				<div class="headTit" @click="homeClick()" >
					首页
				</div>
				<div class="headTit" @click="newsClick()"  >
					新闻动态
				</div>
				<div class="headTit" @click="productClick()">
					产品方案
				</div>
				<!-- <div class="headTit" style="color: #ff8c2e;">
					解决方案
				</div> -->
				<div class="headTit" @click="aboutClick()">
					关于我们
				</div>
		
		
			</div>
		
		</div>
		<div class="headView" style="background-color: white;" v-show="color == true">
			<div :style="style" :class="['headLeft', { color: color }]">
				<img src="../../assets/homeimg/profile.png" style="height: 60px;padding-left: 40px;" alt="" />
				<div >
					辽宁烽火台科技有限公司
				</div>
			</div>
			<div :style="style" :class="['headRight', { color: color }]">
				<div class="headTit" @click="homeClick()">
					首页
				</div>
				<div class="headTit" @click="newsClick()"  >
					新闻动态
				</div>
				<div class="headTit" @click="productClick()">
					产品方案
				</div>
				<!-- <div class="headTit" style="color: #ff8c2e;">
					解决方案
				</div> -->
				<div class="headTit" @click="aboutClick()">
					关于我们
				</div>
		
		
			</div>
		
		</div>
		<div class="imgView">
			<div class="contentView">
				<div class="contentTit" data-aos="flip-up" style="color: rgba(255, 255, 255, 0.2);" >
					Product Center.
				</div>
				<div class="contentTit2" data-aos="flip-down" style="color: rgb(255, 255, 255);">
					产品中心
				</div>
			</div>
		</div>
		
		<div  style="width: 100%;display: flex;flex-direction: column;justify-content: center;align-content: center;align-items: center;">
			<div class="detailTitle" >
				{{newsData.title}}
			</div>
			
			
			<div class="detailView">
				<div class="ql-editor" v-html="newsData.content"></div>
			</div>
			<div class="detailTitle2">
				<div>
					创建人:{{newsData.createUser}}
				</div>
				<div>
					阅读数量:{{newsData.scanCount}}
				</div>
				
				<div>
					创建时间:{{newsData.createTime}}
				</div>
			</div>
			
			<div class="detailView2">
				<el-button style="margin-left: auto;" type="warning" @click="backClick()">返回</el-button>
			</div>
			
		</div>
		
		<div class="footView"
			style="background-color: black;width: 100%;height: 300px;display: flex;justify-content: center;align-items: center;flex-direction: column;">
			<div style="width: 50%;color: #ffffff;font-size: 14px;display: flex;justify-content: center;">
				<div style="width: 50%;">
					<div>
						辽宁烽火台科技有限公司官网
					</div>
					<div style="margin-top: 20px;">
						电话：400-1023458
					</div>
					
					<div style="margin-top: 20px;">
						地址：辽宁省鞍山市高新技术开发区越岭路256号
					</div>
				</div>
				<div >
					<img src="../../assets/homeimg/gongzhong.jpg" style="width: 100px;height: 100px;" alt="" />
					<div>
						关注微信公众号
					</div>
				</div>
			</div>
			<div style="width: 50%;color: #ffffff;font-size: 14px;display: flex;justify-content: center;flex-direction: column;align-content: center;align-items: center;">
				<div style="margin-top: 20px;">
					<a style="color: white;" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">辽ICP备14005170号-1</a>
				</div>
				<div style="margin-top: 20px;">
					<a style="color: white;" href="https://dxzhgl.miit.gov.cn/" target="_blank">增值电信业务经营许可证：辽B2-2014143</a>
				</div>
			</div>
		</div>



	</div>
</template>
<script>
	import BannerFade from './BannerSlide.vue'
	import 'quill/dist/quill.core.css'
	import 'quill/dist/quill.snow.css'
	import 'quill/dist/quill.bubble.css'
	import { quillEditor } from 'vue-quill-editor'
	import {solutionInfo} from "../../api/home/home.js"
	

	import VueCountUp from 'vue-countupjs' //引入
	import {
		v4
	} from 'uuid';
	import {
		getSign
	} from '@/utils'

	export default {
		name: "home",
		components: {
			BannerFade
		},
		data() {
			return {
				fullWidth: document.documentElement.clientWidth,
				fullHeight: document.documentElement.clientHeight,
				newsData:'',
				style: {
					backgroundColor: ""
				},
				color: false,
				scrollTop: 0,
				list:['1','1','1','1','1','1','1']
			};
		},
		watch: {
			fullHeight(val) {

				if (!this.timer) {
					this.fullHeight = val
					this.timer = true
					let that = this
					setTimeout(function() {
						that.timer = false
					}, 400)
				}
			},
			fullWidth(val) {

				if (!this.timer) {
					this.fullWidth = val
					this.timer = true

					let that = this
					setTimeout(function() {
						that.timer = false
					}, 400)
				}
			}
		},
		computed: {

		},
		created() {
			this.createData();
			// this.get_bodyWidth();
			// this.get_bodyHeight();


		},

		methods: {
			createData() {
				const solutionId = this.$route.query.solutionId
				var that = this
				solutionInfo(solutionId).then(response => {
					this.newsData = response.data;
				
				}).catch(() => {});

			},
			getNowTime() {
				var date = new Date();
				var time = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + this.addZero(date.getDate()) + ' ' +
					this.addZero(date.getHours()) + ':' + this.addZero(date.getMinutes()) + ':' + this.addZero(date
						.getSeconds());
				this.newTime = time;
			},
			//根据自己的需求，看要不要在时间不大于10的时候在前面补0，如果需要直接this.addZero(date.getMinutes()),其它与之相同，如果不需要删掉addZero（）方法即可。
			//小于10的拼接上0字符串
			addZero(s) {
				return s < 10 ? ('0' + s) : s;
			},

			get_bodyHeight() {
				const that = this
				window.onresize = () => {
					return (() => {
						window.fullHeight = document.documentElement.clientHeight
						that.fullHeight = window.fullHeight
					})()
				}
			},
			get_bodyWidth() {
				const that = this
				window.onresize = () => {
					return (() => {
						window.fullWidth = document.documentElement.clientWidth
						that.fullWidth = window.fullWidth
					})()
				}
			},
			handleScroll() {
				let scrollTop =
					window.pageYOffset ||
					document.documentElement.scrollTop ||
					document.body.scrollTop;
				if (scrollTop >= 630) {
					this.style.color = `rgba(0, 0, 0,${
				  scrollTop / (scrollTop + 20)
				}) `;
					this.color = true;
				} else if (scrollTop == 0) {
					this.style.color = "white";
					this.color = false;
				}
			},
			//首页
			homeClick(){
				this.$router.push({
				  name: 'home',
				  
				})
			},
			//关于我们
			aboutClick(){
				this.$router.push({
				  name: 'aboutUs',
				  
				})
			},
			newsClick(){
				this.$router.push({
				  name: 'news',
				  
				})
			},
			productClick(){
				this.$router.push({
				  name: 'Solution',
				  
				})
			},
			backClick(){
				this.$router.push({
				  name: 'Solution',
				  
				})
			},
			
			
			

		},
		mounted() {
			window.addEventListener("scroll", this.handleScroll);
		},
		beforeDestroy() {
			window.removeEventListener("scroll", this.handleScroll);
		},

	};
</script>

<style lang="scss" scoped>
	.rootView {
		width: 100%;
	}

	.headView {
		position: fixed;
		width: 100%;
		z-index: 9999;
		display: flex;
		
		height: 80px;
	}

	.headLeft{
		display: flex;
		justify-content:center;
		align-items: center;
		font-size: 25px;
		font-family: led;
		color: white;
	}

	.headRight {
		float: right;
		position: absolute;
		right: 50px;
		width: 500px;
		color: white;
		// font-weight: bold;
		font-family: led;
		
		font-size: 20px;
		text-align: right;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.headTit{
		padding-top: 30px;
	}
	@font-face {
		font-family: led;
		src: url('../../../public/AlimamaShuHeiTi-Bold.ttf');
	}
	.imgView{
		width: 100%;
		height: 630px;
		background: url("../../assets/homeimg/-48696.jpg") no-repeat 0/100% 100%;
		justify-content: center;
		display: flex;
		align-items: center;
	}
	.contentView{
		width: 50%;
		
		display: flex;
		flex-direction: column;	
	}
	.contentTit{
		font-family: "Arial Black";
		font-weight: bold;
		font-size: 72px;
		color: rgba(255, 255, 255, 0.2);
	}
	.contentTit2{
		font-family: "Source Han Sans";
		font-weight: bold;
		font-size: 36px;
		margin-top: 10px;
		color: rgb(255, 255, 255);
	}
	.newsView{
		width: 100%;
		background-color: white;
		display: flex;
		margin-top: 50px;
		flex-direction: column;
		justify-content: center;
		align-content: center;
		align-items: center;
		margin-bottom: 20px;
	}
	.detailTitle{
		
		color: #00693e;
		font-size: 28px;
		font-weight: normal;
		font-style: normal;
		text-decoration: none;
		text-align: center;
		line-height: 40px;
		margin-top: 20px;
		margin-bottom: 20px;
	}
	.detailTitle2{
		display: flex;
		width: 50%;
		font-size: 20px;
		color: #9b9b9b;
		justify-content: space-between;
		margin-bottom: 25px;
		margin-top: 40px;
	}
	.detailView{
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.detailView2{
		width: 50%;
		margin-bottom: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.footView{
		background-image: url("../../assets/homeimg/footImg.png");
		background-position: 50% 50%;
		background-size: cover;
		
	}
	
	
	
	
	

</style>