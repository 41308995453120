<template>
	<div class="rootView">


		<div class="headView" style="background-color:rgba(0, 0, 0, 0.3) " v-show="color == false">
			<div :style="style" :class="['headLeft', { color: color }]">
				<img src="../../assets/homeimg/profile.png" style="height: 60px;padding-left: 40px;" alt="" />
				<div >
					辽宁烽火台科技有限公司
				</div>
			</div>
			<div :style="style" :class="['headRight', { color: color }]">
				<div class="headTit" @click="homeClick()" >
					首页
				</div>
				<div class="headTit" @click="newsClick()">
					新闻动态
				</div>
				<div class="headTit" @click="productClick()">
					产品方案
				</div>
				<!-- <div class="headTit" @click="SolutionClick()">
					解决方案
				</div> -->
				<div class="headTit" style="color: #ff8c2e;">
					关于我们
				</div>


			</div>

		</div>
		<div class="headView" style="background-color: white;" v-show="color == true">
			<div :style="style" :class="['headLeft', { color: color }]">
				<img src="../../assets/homeimg/profile.png" style="height: 60px;padding-left: 40px;" alt="" />
				<div >
					辽宁烽火台科技有限公司
				</div>
			</div>
			<div :style="style" :class="['headRight', { color: color }]">
				<div class="headTit" @click="homeClick()">
					首页
				</div>
				<div class="headTit" @click="newsClick()">
					新闻动态
				</div>
				<div class="headTit" @click="productClick()">
					产品方案
				</div>
				<!-- <div class="headTit" @click="SolutionClick()">
					解决方案
				</div> -->
				<div class="headTit" style="color: #ff8c2e;">
					关于我们
				</div>


			</div>

		</div>
		<div class="imgView">
			<div class="contentView">
				<div class="contentTit" data-aos="fade-right" >
					Company.
				</div>
				<div class="contentTit2" data-aos="fade-left">
					关于我们
				</div>
				
				<div class="contentTit3" data-aos="zoom-in-up">

				
				</div>
			</div>
		</div>
		<div style="width: 100%;display: flex;justify-content: center;align-items: center;background-color: #F5F5F5;">
			<div class="contentNumView">
				<div class="contentNum" data-aos="fade-right">
					<img src="../../assets/homeimg/drop.png" style="width: 56px;height: 56px;" alt="" />
					<div class="contentNumT">
						50,000
					</div>
					<div class="contentNumT2">
						连接数据节点
					</div>
				</div>
				<div class="contentNum" data-aos="fade-down">
					<img src="../../assets/homeimg/people.png" style="width: 56px;height: 56px;" alt="" />
					<div class="contentNumT">
						20,000
					</div>
					<div class="contentNumT2">
						覆盖人口
					</div>
				</div>
				<div class="contentNum" data-aos="fade-left">
					<img src="../../assets/homeimg/area.png" style="width: 56px;height: 56px;" alt="" />
					<div class="contentNumT">
						80,000
					</div>
					<div class="contentNumT2">
						覆盖面积（m²）
					</div>
				</div>
			</div>
		</div>
		<div class="ceoView">
			
			<div class="ceoCentView">
				<div class="ceoViewH">
					<div class="ceoViewHT" data-aos="zoom-out">
						公司简介
					</div>
					<div class="ceoViewHT2" data-aos="zoom-out-up">
						Company Introduction.
					</div>
				</div>
				<div class="ceoViewTit">
					<div class="ceoViewTitView" data-aos="zoom-out-right">
						<span>
						辽宁烽火台科技有限公司成立于2010年，是响应国家“十三五”战略规划的号召，大力发展北斗导航卫星技术产业而创立的北斗民用企业。目前是东北地区创立最早、技术力量最强、队伍规模最大的北斗/数字-定位通信高科技产品研发及销售为一体的民营企业。总部位于辽宁鞍山市高新区激光产业园南园。
						</span>
						<span>
							公司致力于北斗卫星导航技术与各类新一代信息技术融合，打造贯穿数据共享、汇聚、治理、AI加工、可视化到数据要素交易的全数据要素生命周期产品矩阵，包括3大产品体系，场景纵深应用、IOC类（数字城市智能运行中心）与城市数据资产运营管理。场景纵深应用产品是以“数字+场景”模式，打造数字安防、数字货运、数字交通等以解决某领域问题为核心的场景纵深应用系列产品。IOC类（数字城市智能运行中心）产品包含地市级IOC、区县级IOC、IOC专题应用、数字社区、一网统管（市域治理）、数字园区，高质量推进数字政务建设，助力实现国家治理现代化。数据资产运营产品，以打造数据资产运营大平台，建立数据处理、服务、分析、治理子平台，提供支撑、流通、交易等功能，促进数据安全流通。
						</span>
						<span>
							以交通运输领域为例，搭建的“北斗卫星导航技术应用大数据服务系统”，在全国1152家道路货运平台服务企业中位居前40名，服务近10万余客运/货运车，覆盖辽宁省内40%总货运车辆，鞍山市内80%货运车辆。
							公司拥有研发人员50余人，在长沙设有全资子公司1家、分公司4家、技术团队3组与大连理工大学、东北大学、沈阳航空航天大学等单位开展广泛的产学研合作，共同组建“技术研究中心”。
							公司拥有发明专利、软件著作权等知识产权100余项。近年来，公司先后荣获国家四部委认定全国道路货运车辆公共监管与服务平台服务运营企业、国家高新技术企业、辽宁地区北斗卫星位置信息服务技术领域的领军企业、辽宁省科技型中小企业、辽宁省中小企业北斗位置信息公共技术服务平台、鞍山市创新创业企业、中国卫星导航产业十佳运营商、中国最具竞争力智能公交品牌企业、中国交通运输信息化、智能化优秀项目企业、辽宁省示范平台优秀企业、辽宁省“专精特新”中小企业等35项殊荣。
						</span>
						<span>
							辽宁烽火台创立至今，已逾十四载，历经“北斗+”时代，到“+北斗”时代，再到如今的区域数字生态创新领跑者时代；从产品级应用开发，到现在产业级应用研发，已经将ToB政府和企事业单位服务，与数字生态更好地进行了融合，跑出了适合地区数字经济发展的新模式。
							辽宁烽火台将自身定位为中国领先的北斗导航技术应用服务商，也是区域数字创新生态系统创立者，更是推动地区数字经济发展的卓越践行者，希望通过打造开放、共赢的生态合作体系，助力用户智慧运营，获得持续增长和商业成功。
						</span>

					</div>
					<!-- <img  data-aos="zoom-out-left" src="../../assets/homeimg/50831f4bc9bd20270.jpg" style="width: 500px; height: 350px;margin-left: 30px;" alt="" /> -->
				</div>
			</div>
			
		</div>
		<div class="newsView">
			<div class="contentTit" data-aos="zoom-in" style="color: #7b7b7b;">
				Honorary Qualifications.
			</div>
			<div class="contentTit2" style="color: #1e1e1e;" data-aos="zoom-in">
				荣誉资质
			</div>
			
			
			<div style="width: 50%;">
				<div class="programmeView"   data-aos="zoom-in-down">
					<div class="progrView">
						<el-image fit="cover"
						    style="width: 400px; height: 200px;margin-left: 12px;"
						    :src="require('@/assets/homeimg/honor1.jpg')"
						    :preview-src-list="[require('@/assets/homeimg/honor1.jpg')]"
						>
						</el-image>
						<el-image fit="cover"
						    style="width: 400px; height: 200px;margin-left: 12px;"
						    :src="require('@/assets/homeimg/honor2.jpg')"
						    :preview-src-list="[require('@/assets/homeimg/honor2.jpg')]"
						>
						</el-image>
						<el-image fit="cover"
						    style="width: 400px; height: 200px;margin-left: 12px;"
						    :src="require('@/assets/homeimg/honor3.jpg')"
						    :preview-src-list="[require('@/assets/homeimg/honor3.jpg')]"
						>
						</el-image>
						<el-image fit="cover"
						    style="width: 400px; height: 200px;margin-left: 12px;"
						    :src="require('@/assets/homeimg/honor4.jpg')"
						    :preview-src-list="[require('@/assets/homeimg/honor4.jpg')]"
						>
						</el-image>
						<el-image fit="cover"
						    style="width: 400px; height: 200px;margin-left: 12px;"
						    :src="require('@/assets/homeimg/honor5.jpg')"
						    :preview-src-list="[require('@/assets/homeimg/honor5.jpg')]"
						>
						</el-image>
						
						
					</div>
					
				</div>
			
			
			</div>

		</div>
		
		
		
		<div class="mapView">
			<div class="ceoCentView2">
				
				<div class="ceoViewTit">
					<div id="container">
					</div>
					<div class="ceoViewTitView2" style="margin-left: 40px;" data-aos="zoom-out-right">
						<div class="ceoViewH" style="display: flex;flex-direction: column;">
							<div class="ceoViewHT" data-aos="zoom-out">
								公司地址
							</div>
							<div class="ceoViewHT3" data-aos="zoom-out-up">
								Company Address.
							</div>
						</div>
						
						<span>地址：辽宁省鞍山市高新技术开发区越岭路256号</span>
						<span>电话：400-1023458</span>
	
					</div>
					
				</div>
			</div>
			
			
			
			
		</div>
		<div class="newsView" style="background-color: white;">
			<div class="ceoViewHT2" data-aos="fade-right" style="font-size: 36px;">
				Partners.
			</div>
			<div class="contentTit2" style="color: #1e1e1e;" data-aos="fade-right">
				合作伙伴
			</div>
			
			
			<div style="width: 50%;margin-top: 30px;margin-bottom: 30px;">
				<div class="programmeView2" >
					<div class="progrView2">
						<img data-aos="fade-up-left" src="../../assets/homeimg/img1.png" style="width: 150px; height: 80px;margin-left: 2px;" alt="" />
						<img data-aos="fade-up-left" src="../../assets/homeimg/img2.png" style="width: 150px; height: 80px;margin-left: 2px;" alt="" />
						<img data-aos="fade-up-left" src="../../assets/homeimg/img3.png" style="width: 150px; height: 80px;margin-left: 2px;" alt="" />
						<img data-aos="fade-up-left" src="../../assets/homeimg/img4.png" style="width: 150px; height: 80px;margin-left: 2px;" alt="" />
						
					</div>
					<div class="progrView2">
						<img data-aos="fade-up-left" src="../../assets/homeimg/img5.png" style="width: 150px; height: 80px;margin-left: 2px;" alt="" />
						<img data-aos="fade-up-left" src="../../assets/homeimg/img6.png" style="width: 150px; height: 80px;margin-left: 2px;" alt="" />
						<img data-aos="fade-up-left" src="../../assets/homeimg/img7.png" style="width: 150px; height: 80px;margin-left: 2px;" alt="" />
						<img data-aos="fade-up-left" src="../../assets/homeimg/img8.png" style="width: 150px; height: 80px;margin-left: 2px;" alt="" />
						
					</div>
					<div class="progrView2">
						<img data-aos="fade-up-left" src="../../assets/homeimg/img9.png" style="width: 150px; height: 80px;margin-left: 2px;" alt="" />
						<img data-aos="fade-up-left" src="../../assets/homeimg/img10.png" style="width: 150px; height: 80px;margin-left: 2px;" alt="" />
						<img data-aos="fade-up-left" src="../../assets/homeimg/img11.png" style="width: 150px; height: 80px;margin-left: 2px;" alt="" />
						<img data-aos="fade-up-left" src="../../assets/homeimg/img12.png" style="width: 150px; height: 80px;margin-left: 2px;" alt="" />
						
					</div>
					
				</div>
				
			
			
			</div>
		
		</div>
		
		
		
		
		
		

		<div class="footView"
			style="background-color: black;width: 100%;height: 300px;display: flex;justify-content: center;align-items: center;flex-direction: column;">
			<div style="width: 50%;color: #ffffff;font-size: 14px;display: flex;justify-content: center;">
				<div style="width: 50%;">
					<div>
						辽宁烽火台科技有限公司官网
					</div>
					<div style="margin-top: 20px;">
						电话：400-1023458
					</div>
					
					<div style="margin-top: 20px;">
						地址：辽宁省鞍山市高新技术开发区越岭路256号
					</div>
				</div>
				<div >
					<img src="../../assets/homeimg/gongzhong.jpg" style="width: 100px;height: 100px;" alt="" />
					<div>
						关注微信公众号
					</div>
				</div>
			</div>
			<div style="width: 50%;color: #ffffff;font-size: 14px;display: flex;justify-content: center;flex-direction: column;align-content: center;align-items: center;">
				<div style="margin-top: 20px;">
					<a style="color: white;" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">辽ICP备14005170号-1</a>
				</div>
				<div style="margin-top: 20px;">
					<a style="color: white;" href="https://dxzhgl.miit.gov.cn/" target="_blank">增值电信业务经营许可证：辽B2-2014143</a>
				</div>
			</div>
		</div>



	</div>
</template>
<script>
	import BannerFade from './BannerSlide.vue'

	import VueCountUp from 'vue-countupjs' //引入
	import {
		v4
	} from 'uuid';
	import {
		getSign
	} from '@/utils'

	export default {
		name: "home",
		components: {
			BannerFade
		},
		data() {
			return {
				fullWidth: document.documentElement.clientWidth,
				fullHeight: document.documentElement.clientHeight,
				imageData: [{
						title: 'image-3',
						imgUrl: require('@/assets/homeimg/image-3.jpg')
					},
					{
						title: 'image-2',
						imgUrl: require('@/assets/homeimg/image-2.png')
					}
				],
				url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
				srcList: [
				  'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
				  'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg',
				  'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
				  'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'
				],
				srcList2: [
				  '../../assets/homeimg/img1.png',
				  '../../assets/homeimg/img2.png',
				  '../../assets/homeimg/img3.png',
				  '../../assets/homeimg/img4.png',
				  '../../assets/homeimg/img5.png',
				  '../../assets/homeimg/img6.png',
				  '../../assets/homeimg/img7.png',
				  '../../assets/homeimg/img8.png',
				  '../../assets/homeimg/img9.png',
				  '../../assets/homeimg/img10.png',
				  '../../assets/homeimg/img11.png',
				  '../../assets/homeimg/img12.png',
				],
				map: null,
				infoWindow: null,
				markers: null,
				style: {
					backgroundColor: ""
				},
				color: false,
				scrollTop: 0,
			};
		},
		watch: {
			fullHeight(val) {

				if (!this.timer) {
					this.fullHeight = val
					this.timer = true
					let that = this
					setTimeout(function() {
						that.timer = false
					}, 400)
				}
			},
			fullWidth(val) {

				if (!this.timer) {
					this.fullWidth = val
					this.timer = true

					let that = this
					setTimeout(function() {
						that.timer = false
					}, 400)
				}
			}
		},
		computed: {

		},
		created() {
			this.createData();
			this.$nextTick(() => {
			  this.initMap()
			})
			// this.get_bodyWidth();
			// this.get_bodyHeight();


		},

		methods: {
			createData() {

			},
			getNowTime() {
				var date = new Date();
				var time = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + this.addZero(date.getDate()) + ' ' +
					this.addZero(date.getHours()) + ':' + this.addZero(date.getMinutes()) + ':' + this.addZero(date
						.getSeconds());
				this.newTime = time;
			},
			//根据自己的需求，看要不要在时间不大于10的时候在前面补0，如果需要直接this.addZero(date.getMinutes()),其它与之相同，如果不需要删掉addZero（）方法即可。
			//小于10的拼接上0字符串
			addZero(s) {
				return s < 10 ? ('0' + s) : s;
			},

			get_bodyHeight() {
				const that = this
				window.onresize = () => {
					return (() => {
						window.fullHeight = document.documentElement.clientHeight
						that.fullHeight = window.fullHeight
					})()
				}
			},
			get_bodyWidth() {
				const that = this
				window.onresize = () => {
					return (() => {
						window.fullWidth = document.documentElement.clientWidth
						that.fullWidth = window.fullWidth
					})()
				}
			},
			handleScroll() {
				let scrollTop =
					window.pageYOffset ||
					document.documentElement.scrollTop ||
					document.body.scrollTop;
				if (scrollTop >= 650) {
					this.style.color = `rgba(0, 0, 0,${
				  scrollTop / (scrollTop + 20)
				}) `;
					this.color = true;
				} else if (scrollTop == 0) {
					this.style.color = "white";
					this.color = false;
				}
			},
			//首页
			homeClick(){
				this.$router.push({
				  name: 'home',
				  
				})
			},
			newsClick(){
				this.$router.push({
				  name: 'news',
				  
				})
			},
			productClick(){
				this.$router.push({
				  name: 'product',
				  
				})
			},
			//解决方案
			SolutionClick(){
				this.$router.push({
				  name: 'Solution',
				  
				})
			},
			initMap() {
			
			
			  let position = document.getElementById("container");
			  let map;
			  
			  let center = new TMap.LatLng(41.1198, 123.093580); //设置中心点坐标
			  map = new TMap.Map(position, {
			    center: center, // 设置地图中心点坐标
			    zoom: 17,
			  })
			  // 123.092224,41.119134
			  // map.setBaseMap({type:'vector'})
			
			  var that = this
			  this.map = map
			  //初始化infoWindow
			  var infoWindow = new TMap.InfoWindow({
				  map: map, 
				  position: center, //设置信息框位置
				  content: '辽宁烽火台科技有限公司' //设置信息框内容
			  });
			}
			

		},
		mounted() {
			window.addEventListener("scroll", this.handleScroll);
		},
		beforeDestroy() {
			window.removeEventListener("scroll", this.handleScroll);
		},

	};
</script>

<style lang="scss" scoped>
	.rootView {
		width: 100%;
	}

	.headView {
		position: fixed;
		width: 100%;
		z-index: 9999;
		display: flex;
		
		height: 80px;
	}

	.headLeft{
		display: flex;
		justify-content:center;
		align-items: center;
		font-size: 25px;
		font-family: led;
		color: white;
	}

	.headRight {
		float: right;
		position: absolute;
		right: 50px;
		width: 500px;
		color: white;
		// font-weight: bold;
		font-family: led;
		
		font-size: 20px;
		text-align: right;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.headTit{
		padding-top: 30px;
	}
	@font-face {
		font-family: led;
		src: url('../../../public/AlimamaShuHeiTi-Bold.ttf');
	}
	.imgView{
		width: 100%;
		height: 850px;
		background: url("../../assets/homeimg/-486487.jpg") no-repeat 0/100% 100%;
		justify-content: center;
		display: flex;
		align-items: center;
		
	}
	.contentView{
		width: 50%;
		
		display: flex;
		flex-direction: column;	
	}
	.contentTit{
		font-family: "Arial Black";
		font-weight: bold;
		font-size: 72px;
		color: rgba(255, 255, 255, 0.2);
	}
	.contentTit2{
		font-family: "Source Han Sans";
		font-weight: bold;
		font-size: 36px;
		margin-top: 10px;
		color: rgb(255, 255, 255);
	}
	.contentTit3{
		width: 50%;
		margin-top: 20px;
		line-height: 2;
		font-size:18px;
		text-align: justify;
		overflow-wrap: break-word;
		color: rgb(255, 255, 255);
	}
	.contentNumView {
		display: flex;
		width: 50%;
		justify-content: space-between;
	}
	.contentNum {
		display: flex;
		flex-direction: column;
		margin-bottom: 40px;
		margin-top: 40px;
	}
	
	.contentNumT {
		color: #856dcc;
		font-family: Arial, Helvetica, sans-serif;
		margin-top: 20px;
		font-size: 62px;
	}
	
	.contentNumT2 {
		font-family: Source Han Sans, Geneva, sans-serif;
		color: #666666;
		margin-top: 20px;
		font-size: 16px;
	}
	.ceoView{
		background-color: white;
		width: 100%;
		display: flex;
		justify-content: center;
	}
	
	.ceoCentView{
		width: 50%;
		display: flex;
		margin-top: 40px;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}
	.ceoCentView2{
		width: 50%;
		display: flex;
		padding-top: 20px;
		flex-direction: column;
	}
	.ceoViewH{
		display: flex;
	}
	.ceoViewHT{
		font-family: Source Han Sans,Geneva,sans-serif;
		font-size: 34px;
		font-weight: bold;
		color: #333333;
	}
	.ceoViewHT2{
		font-family: Arial Black;
		font-size: 32px;
		font-weight: bold;
		margin-left: 15px;
		color: #dddddd;
	}
	.ceoViewHT3{
		font-family: Arial Black;
		font-size: 32px;
		font-weight: bold;
		// margin-left: 15px;
		color: #dddddd;
	}
	.ceoViewTit{
		width: 100%;
		display: flex;
		
	}
	.ceoViewTitView{
		
		margin-top: 30px;
		display: flex;
		flex-direction: column;
	}
	.ceoViewTitView2{
		
		margin-top: 30px;
		display: flex;
		width: 50%;
		flex-direction: column;
	}
	.ceoViewTitView2 span{
		margin-top: 130px;
		font-family: Source Han Sans,Geneva,sans-serif;
		font-size: 20px;
	}
	.ceoViewTitView span{
		margin-top: 20px;
		text-indent: 4ch;
		line-height: 30px;
		font-family: Source Han Sans,Geneva,sans-serif;
		font-size: 20px;
	}
	.footView{
		background-image: url("../../assets/homeimg/footImg.png");
		background-position: 50% 50%;
		background-size: cover;
		
	}
	.mapView{
		background-color: white;
		width: 100%;
		display: flex;
		margin-top: 80px;
		justify-content: center;
	}
	#container{
	    width: 50%;
	    height: 425px;
	    margin-left: 0px;
	    margin-bottom: 20px;
	}
	.newsView{
		width: 100%;
		margin-top: 60px;
		background-color: #F5F5F5;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-content: center;
		align-items: center;
		
	}
	.programmeView {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.programmeView2 {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.progrView{
		width: 100%;
		margin-top: 20px;
		justify-content: space-around;
		display: flex;
		margin-bottom: 20px;
	}
	.progrView2{
		width: 100%;
		margin-top: 20px;
		justify-content: space-around;
		display: flex;
	}
	

</style>