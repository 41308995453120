<template>
	<div class="rootView">


		<div class="headView" style="background-color:rgba(0, 0, 0, 0.3) " v-show="color == false">
			<div :style="style" :class="['headLeft', { color: color }]">
				<img src="../../assets/homeimg/profile.png" style="height: 60px;padding-left: 40px;" alt="" />
				<div >
					辽宁烽火台科技有限公司
				</div>
			</div>
			<div :style="style" :class="['headRight', { color: color }]">
				<div class="headTit" @click="homeClick()" >
					首页
				</div>
				<div class="headTit" @click="newsClick()"  >
					新闻动态
				</div>
				<div class="headTit" style="color: #ff8c2e;">
					产品方案
				</div>
				<!-- <div class="headTit" @click="SolutionClick()">
					解决方案
				</div> -->
				<div class="headTit" @click="aboutClick()">
					关于我们
				</div>


			</div>

		</div>
		<div class="headView" style="background-color: white;" v-show="color == true">
			<div :style="style" :class="['headLeft', { color: color }]">
				<img src="../../assets/homeimg/profile.png" style="height: 60px;padding-left: 40px;" alt="" />
				<div >
					辽宁烽火台科技有限公司
				</div>
			</div>
			<div :style="style" :class="['headRight', { color: color }]">
				<div class="headTit" @click="homeClick()">
					首页
				</div>
				<div class="headTit" @click="newsClick()"  >
					新闻动态
				</div>
				<div class="headTit" style="color: #ff8c2e;">
					产品方案
				</div>
				<!-- <div class="headTit" @click="SolutionClick()">
					解决方案
				</div> -->
				<div class="headTit" @click="aboutClick()">
					关于我们
				</div>


			</div>

		</div>
		<div class="imgView">
			<div class="contentView">
				<div class="contentTit" data-aos="flip-up" style="color: rgba(255, 255, 255, 0.2);" >
					Product Center.
				</div>
				<div class="contentTit2" data-aos="flip-down" style="color: rgb(255, 255, 255);">
					产品中心
				</div>
			</div>
		</div>
		<div class="newsView">
			<div class="contentTit" data-aos="zoom-in" style="color: #eeeeee;">
				Software.
			</div>
			<div class="contentTit2" data-aos="zoom-in">
				软件开发
			</div>
			<div class="contentTit3">
			
			</div>
			<div class="contentTit4" >
				以人工智能+物联网技术打造 AI CITY, 为政府、企业提供城市管理、建筑能源管理、环境与基础设施运营管理等一站式解决方案。
			
			</div>
			<div style="background-color: white;width: 50%;display: flex;justify-content: center;align-items: center;">
				<div class="programmeView" v-for="(item, index) in productList1" :key="index" @click="detailsClick1(item)" data-aos="zoom-in-down">
					<div class="progrView">
						<img  :src="item.coverImg" alt=""style="width: 100%;height: 200px;object-fit: cover;
" ></img>
						
						<div class="">
							{{item.title}}
						</div>
					
					</div>
					
				</div>
			
			
			</div>
			<div style="width: 50%;display: flex;justify-content: center;align-items: center;margin-top: 40px;">
				<el-button type="primary" @click="moreSoftClick()">更多</el-button>
			</div>
			
			

		</div>
		
		<div class="newsView2">
			<div class="contentTit" data-aos="zoom-in-down" style="color: #eeeeee;">
				Hardware.
			</div>
			<div class="contentTit2" data-aos="zoom-in-down">
				硬件开发
			</div>
			<div class="contentTit3" data-aos="zoom-in-down">
			
			</div>
			<div class="contentTit4" data-aos="zoom-in-down">
				以人工智能+物联网技术打造 AI CITY, 为政府、企业提供城市管理、建筑能源管理、环境与基础设施运营管理等一站式解决方案。
			
			</div>
			<div style="background-color: white;width: 50%;display: flex;justify-content: center;align-items: center;">
				<div class="programmeView" v-for="(item, index) in productList2" :key="index" @click="detailsClick2(item)" data-aos="zoom-in-down">
					<div class="progrView">
						<img  :src="item.coverImg" alt=""style="width: 100%;height: 200px;object-fit: cover;" ></img>
						
						<div class="">
							{{item.title}}
						</div>
					
					</div>
					
				</div>
				
				
			
			
			</div>
			<div style="width: 50%;display: flex;justify-content: center;align-items: center;margin-top: 40px;">
				<el-button type="primary" @click="moreSoftClick2()">更多</el-button>
			</div>
		</div>
		<!-- <div class="imgView2">
			<div class="contentView">
				<div class="contentTit" style="color: rgba(255, 255, 255, 0.2);" data-aos="fade-down-right" >
					Solution.
				</div>
				<div class="contentTit2" style="color: rgba(255, 255, 255, 0.2);" data-aos="fade-down-left">
					解决方案
				</div>
			</div>
		</div> -->
		<!-- <div class="newsView" v-for="(item, index) in solutionList" :key="index" @click="detailsClick1(item)" >
			<div class="newsListView" v-if="index%2 == 0" >
				<img data-aos="fade-up-left" :src="item.coverImg" style="width: 50%;height: 530px;" alt="" srcset="" />
				<div class="newsListTit" data-aos="fade-up-right">
					<div class="newsListTit1">
						{{item.title}}
					</div>
					<div class="newsListTitLine">
						
					</div>
					<div class="newsListTit2">
		                 创建人:{{item.createUser}}
					</div>
					
				</div>
			</div>
			
			<div class="newsListView" v-else data-aos="fade-up-right">
				
				<div class="newsListTit">
					<div class="newsListTit1">
						{{item.title}}
					</div>
					<div class="newsListTitLine">
						
					</div>
					<div class="newsListTit2">
		                 创建人:{{item.createUser}}
					</div>
					
				</div>
				<img data-aos="fade-up-left" :src="item.coverImg" style="width: 50%;height: 530px;" alt="" srcset="" />
			</div>
			
			
			
			
			
		
		</div> -->
		
		
		
		
		
		<div class="footView"
			style="background-color: black;width: 100%;height: 300px;display: flex;justify-content: center;align-items: center;flex-direction: column;">
			<div style="width: 50%;color: #ffffff;font-size: 14px;display: flex;justify-content: center;">
				<div style="width: 50%;">
					<div>
						辽宁烽火台科技有限公司官网
					</div>
					<div style="margin-top: 20px;">
						电话：400-1023458
					</div>
					
					<div style="margin-top: 20px;">
						地址：辽宁省鞍山市高新技术开发区越岭路256号
					</div>
				</div>
				<div >
					<img src="../../assets/homeimg/gongzhong.jpg" style="width: 100px;height: 100px;" alt="" />
					<div>
						关注微信公众号
					</div>
				</div>
			</div>
			<div style="width: 50%;color: #ffffff;font-size: 14px;display: flex;justify-content: center;flex-direction: column;align-content: center;align-items: center;">
				<div style="margin-top: 20px;">
					<a style="color: white;" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">辽ICP备14005170号-1</a>
				</div>
				<div style="margin-top: 20px;">
					<a style="color: white;" href="https://dxzhgl.miit.gov.cn/" target="_blank">增值电信业务经营许可证：辽B2-2014143</a>
				</div>
			</div>
		</div>



	</div>
</template>
<script>
	import BannerFade from './BannerSlide.vue'
	
	import {productPage,solutionPage} from "../../api/home/home.js"
	
	import VueCountUp from 'vue-countupjs' //引入
	import {
		v4
	} from 'uuid';
	import {
		getSign
	} from '@/utils'

	export default {
		name: "home",
		components: {
			BannerFade
		},
		data() {
			return {
				fullWidth: document.documentElement.clientWidth,
				fullHeight: document.documentElement.clientHeight,
				queryParams: {
				  page: 1,
				  size: 3,
				  type:''
				},
				productList1:[],
				productList2:[],
				solutionList:[],
				style: {
					backgroundColor: ""
				},
				color: false,
				scrollTop: 0,
				list:['1','1','1','1','1','1','1']
			};
		},
		watch: {
			fullHeight(val) {

				if (!this.timer) {
					this.fullHeight = val
					this.timer = true
					let that = this
					setTimeout(function() {
						that.timer = false
					}, 400)
				}
			},
			fullWidth(val) {

				if (!this.timer) {
					this.fullWidth = val
					this.timer = true

					let that = this
					setTimeout(function() {
						that.timer = false
					}, 400)
				}
			}
		},
		computed: {

		},
		created() {
			this.getList();
			// this.get_bodyWidth();
			// this.get_bodyHeight();


		},

		methods: {
			getList() {
				var that = this
				that.productList1 = []
				that.productList2 = []
				productPage(this.queryParams.page,this.queryParams.size,'软件开发').then(response => {
					that.productList1 = response.data.list;
					
				
				}).catch(() => {});
				
				productPage(this.queryParams.page,this.queryParams.size,'硬件开发').then(response => {
					that.productList2 = response.data.list;
					
				
				}).catch(() => {});
				
				solutionPage(this.queryParams.page,this.queryParams.size).then(response => {
					this.solutionList = response.data.list;
				
				}).catch(() => {});
				
			
			},
			createData() {

			},
			getNowTime() {
				var date = new Date();
				var time = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + this.addZero(date.getDate()) + ' ' +
					this.addZero(date.getHours()) + ':' + this.addZero(date.getMinutes()) + ':' + this.addZero(date
						.getSeconds());
				this.newTime = time;
			},
			//根据自己的需求，看要不要在时间不大于10的时候在前面补0，如果需要直接this.addZero(date.getMinutes()),其它与之相同，如果不需要删掉addZero（）方法即可。
			//小于10的拼接上0字符串
			addZero(s) {
				return s < 10 ? ('0' + s) : s;
			},

			get_bodyHeight() {
				const that = this
				window.onresize = () => {
					return (() => {
						window.fullHeight = document.documentElement.clientHeight
						that.fullHeight = window.fullHeight
					})()
				}
			},
			get_bodyWidth() {
				const that = this
				window.onresize = () => {
					return (() => {
						window.fullWidth = document.documentElement.clientWidth
						that.fullWidth = window.fullWidth
					})()
				}
			},
			handleScroll() {
				let scrollTop =
					window.pageYOffset ||
					document.documentElement.scrollTop ||
					document.body.scrollTop;
				if (scrollTop >= 500) {
					this.style.color = `rgba(0, 0, 0,${
				  scrollTop / (scrollTop + 20)
				}) `;
					this.color = true;
				} else if (scrollTop == 0) {
					this.style.color = "white";
					this.color = false;
				}
			},
			//首页
			homeClick(){
				this.$router.push({
				  name: 'home',
				  
				})
			},
			//关于我们
			aboutClick(){
				this.$router.push({
				  name: 'aboutUs',
				  
				})
			},
			newsClick(){
				this.$router.push({
				  name: 'news',
				  
				})
			},
			//解决方案
			SolutionClick(){
				this.$router.push({
				  name: 'Solution',
				  
				})
			},
			moreSoftClick(){
				this.$router.push({
				  name: 'productList',
				  
				})
			},
			moreSoftClick2(){
				this.$router.push({
				  name: 'productList2',
				  
				})
			},
			//详情
			detailsClick1(row){
				const productId = row.productId
				const query = { productId: productId}
				this.$router.push({
				  name: 'productDetail',
				  query: query
				  
				})
			},
			detailsClick2(row){
				const productId = row.productId
				const query = { productId: productId}
				this.$router.push({
				  name: 'productDetail',
				  query: query
				  
				})
			},
			moreSoftClick3(row){
				const productId = row.productId
				const query = { productId: productId}
				this.$router.push({
				  name: 'productList3',
				  query: 'query'
				  
				})
			},
			
			
			
			

		},
		mounted() {
			window.addEventListener("scroll", this.handleScroll);
		},
		beforeDestroy() {
			window.removeEventListener("scroll", this.handleScroll);
		},

	};
</script>

<style lang="scss" scoped>
	.rootView {
		width: 100%;
	}

	.headView {
		position: fixed;
		width: 100%;
		z-index: 9999;
		display: flex;
		
		height: 80px;
	}

	.headLeft{
		display: flex;
		justify-content:center;
		align-items: center;
		font-size: 25px;
		font-family: led;
		color: white;
	}

	.headRight {
		float: right;
		position: absolute;
		right: 50px;
		width: 500px;
		color: white;
		// font-weight: bold;
		font-family: led;
		
		font-size: 20px;
		text-align: right;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.headTit{
		padding-top: 30px;
	}
	@font-face {
		font-family: led;
		src: url('../../../public/AlimamaShuHeiTi-Bold.ttf');
	}
	.imgView{
		width: 100%;
		height: 580px;
		background: url("../../assets/homeimg/-48672.jpg") no-repeat 0/100% 100%;
		justify-content: center;
		display: flex;
		align-items: center;
	}
	.imgView2{
		width: 100%;
		height: 600px;
		background-image: url("../../assets/homeimg/-48620.jpg");
		background-position: 50% 50%;
		background-size: cover;
		// background: url("../../assets/homeimg/-48620.jpg") no-repeat 0/100% 100%;
		justify-content: center;
		display: flex;
		align-items: center;
	}
	.contentView{
		width: 50%;
		
		display: flex;
		flex-direction: column;	
	}
	.contentTit{
		font-family: "Arial Black";
		font-weight: bold;
		font-size: 72px;
		
	}
	.contentTit2{
		font-family: "Source Han Sans";
		font-weight: bold;
		font-size: 36px;
		margin-top: 10px;
		
	}
	.contentTit3 {
		width: 50%;
		width: 1px;
		height: 50px;
		background-color: #727272;
		margin-top: 20px;
	}
	
	.contentTit4 {
		width: 50%;
		text-align: center;
		margin-top: 20px;
		color: #727272;
	}
	.newsView{
		width: 100%;
		background-color: white;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-content: center;
		align-items: center;
		
	}
	.newsView2{
		width: 100%;
		background-color: white;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-content: center;
		align-items: center;
		margin-top: 80px;
		margin-bottom: 80px;
	}
	.programmeView {
		width: 50%;
		height: 250px;
		display: flex;
		margin-top: 20px;
		justify-content: space-around;
		align-content: center;
		align-items: center;
		
	}
	.progrView{
		width: 80%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-content: center;
		align-items: center;
	}
	.programmeViewC {
		width: 100%;
		height: 250px;
		background: url("../../assets/homeimg/program.jpg") no-repeat 0/100% 100%;
	}
	.footView{
		background-image: url("../../assets/homeimg/footImg.png");
		background-position: 50% 50%;
		background-size: cover;
		
	}
	
	
	
	
	
	
	

</style>