<template>
  <div id="app">
    <router-view />
    
  </div>
</template>

<script>


export default {
  name: 'App',
  components: {

  }
}
</script>

<style>
	
#app {
	
	height: 100%;
}
body,html{
	
	width: 100%;
	height: 100%;
	margin: 0;
}
</style>
