import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
import router from './router'
// 全局引入echarts
import echarts from 'echarts'
import 'echarts-gl'
import axios from "axios"
import { v4 } from 'uuid';
import Swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.css'

import VueBMap, {initBMapApiLoader} from 'vue-bmap-gl';
import 'vue-bmap-gl/dist/style.css';
import AOS from 'aos'
import 'aos/dist/aos.css' // You can also use <link> for styles

AOS.init()

Vue.use(VueBMap);
VueBMap.initBMapApiLoader({
  ak: 'OtgOqpqGBvEK4O1byYxaxR9pY6amgK8x',//这个密钥请使用自己注册的
  v: '1.0'
});
// 分页组件
import Pagination from "@/components/Pagination";

Vue.component('Pagination', Pagination)


Vue.prototype.$http = axios;

Vue.prototype.$echarts = echarts
Vue.config.productionTip = false
Vue.use(ElementUI)

router.beforeEach((to, from, next) => {
	next();
// 	document.title = to.meta.title;
//     if (to.matched.some(res => res.meta.requireAuth)) { // 验证是否需要登陆
//     if (localStorage.getItem('token')) { // 查询本地存储信息是否已经登陆
//       next();
//     } else {
// 		Toast({
// 			message: "您还没有登录，请去登录",
// 			forbidClick: true,
// 			duration: 3000,
// 			onClose() {
// 			}
// 		});
// 		next({
// 		 	        path: '/login', // 未登录则跳转至login页面
// 		 	  });
//     }
//    } else {
//     next();
//    }
});

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
