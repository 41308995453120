import request from '@/utils/request'


// 首页置顶新闻列表
export function topNews() {
  return request({
    url: '/data/topNews',
    method: 'get'
  })
}

// 新闻详情
export function newsInfo(newsId) {
  return request({
    url: '/data/newsInfo?newsId=' + newsId,
    method: 'get'
  })
}
// 新闻列表
export function newsPage(page,size) {
  return request({
    url: '/data/newsPage?page=' + page + '&size=' + size,
    method: 'get'
  })
}

// 产品模块分页列表
export function productPage(page,size,type) {
  return request({
    url: '/data/productPage?page=' + page + '&size=' + size + '&type=' + type,
    method: 'get'
  })
}

// 产品详情
export function productInfo(productId) {
  return request({
    url: '/data/productInfo?productId=' + productId,
    method: 'get'
  })
}




//解决方案分页列表
export function solutionPage(page,size) {
  return request({
    url: '/data/solutionPage?page=' + page + '&size=' + size,
    method: 'get'
  })
}
// 产品详情
export function solutionInfo(solutionId) {
  return request({
    url: '/data/solutionInfo?solutionId=' + solutionId,
    method: 'get'
  })
}


