import Vue from 'vue'
import Router from 'vue-router'



Vue.use(Router)

//引入页面
import home from '../views/home/index.vue'
import aboutUs from '../views/home/aboutUs.vue'
import news from '../views/home/news.vue'
import product from '../views/home/product.vue'
import Solution from '../views/home/Solution.vue'
import newsDet from '../views/home/newsDet.vue'
import productList from '../views/home/productList.vue'
import productList2 from '../views/home/productList2.vue'
import productList3 from '../views/home/productList3.vue'
import productDetail from '../views/home/productDetail.vue'
import SolutionDetail from '../views/home/SolutionDetail.vue'



const routes= [
  {
	path: '/',
	name: 'home',
	component:home,
  },
  {
  	path: '/aboutUs',
  	name: 'aboutUs',
  	component:aboutUs,
  },
  {
  	path: '/news',
  	name: 'news',
  	component:news,
  },
  {
  	path: '/product',
  	name: 'product',
  	component:product,
  },
  {
  	path: '/Solution',
  	name: 'Solution',
  	component:Solution,
  },
  {
  	path: '/newsDet',
  	name: 'newsDet',
  	component:newsDet,
  },
  {
  	path: '/productList',
  	name: 'productList',
  	component:productList,
  },
  {
  	path: '/productList2',
  	name: 'productList2',
  	component:productList2,
  },
  {
  	path: '/productList3',
  	name: 'productList3',
  	component:productList3,
  },
  
  
  {
  	path: '/productDetail',
  	name: 'productDetail',
  	component:productDetail,
  },
  {
  	path: '/SolutionDetail',
  	name: 'SolutionDetail',
  	component:SolutionDetail,
  },
  
  
  
  
  
  
  
 
];
const router = new Router({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router;
